export default [
    {
        id: "1",
        position:"Business Development Executive",
        role:"Sales position Reporting to Branch Head",
        location:"nagpur | pune | amravati"
    },
    {
        id: "2",
        position:"xyz",
        role:"cfdsf",
        location:"sdf"
    },
    {
        id: "3",
        position:"xyz",
        role:"cfdsf",
        location:"sdf"
    }
];