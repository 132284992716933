export default [
    {
        id:"1",
        title:"navbharat",
        news:[
            {
                year:"2015",
                images:[
                    require('../assets/images/news/1.jpeg'),require('../assets/images/news/2.jpeg'),require('../assets/images/news/2.jpeg'),
                    require('../assets/images/news/2.jpeg'),
                    require('../assets/images/news/2.jpeg'),
                ]
            },
            {
                year:"2017",
                images:[
                    require('../assets/images/news/1.jpeg'),require('../assets/images/news/3.jpg'),
                ]
            },
            {
                year:"2020",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]

            },

            {
                year:"2021",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]

            },
            {
                year:"2022",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]

            },
            {
                year:"2023",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]

            },
            {
                year:"2024",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]
            }
        ],        
    },
    {
        id:"2",
        title:"lokmat",
        news:[
            {
                year:"2015",
                images:[
                    require('../assets/images/news/1.jpeg'),require('../assets/images/news/2.jpeg'),
                ]
            },
            {
                year:"2017",
                images:[
                    require('../assets/images/news/1.jpeg'),require('../assets/images/news/3.jpg'),
                ]
            },
            {
                year:"2020",
                images:[
                    require('../assets/images/news/1.jpeg'),require('../assets/images/news/4.jpeg'),
                ]

            },
            {
                year:"2020",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]

            },
            {
                year:"2023",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]

            },
            {
                year:"2024",
                images:[
                    require('../assets/images/news/1.jpeg'),
                ]
            }
        ],        
    },
]