import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ContactForm = props => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    };
    return(
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control required type="text" placeholder="Enter name"/>
                    <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control required type="email" placeholder="Enter Email Address" />
                    <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicMobile">
                    <Form.Control required type="tel" placeholder="Enter Mobile Number" />
                    <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
                </Form.Group>
                <Form.Select required aria-label="Default select example" className='mb-3'>
                    <option>Select State</option>
                    <option value="1">Maharashtra</option>
                    <option value="2">Goa</option>
                    <option value="3">Gujrat</option>
                </Form.Select>
                <Form.Select required aria-label="Default select example" className='mb-3'>
                    <option>Select City</option>
                    <option value="1">Thane</option>
                    <option value="2">Mumbai</option>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Select>
                <Form.Select required aria-label="Default select example" className='mb-3'>
                    <option>Select Class Studying In</option>
                    <option value="1">VII</option>
                    <option value="2">VIII</option>
                    <option value="3">IX</option>
                    <option value="4">X</option>
                    <option value="5">XI</option>
                    <option value="6">XII</option>
                </Form.Select>
                <Form.Select required aria-label="Default select example" className='mb-3'>
                    <option>Select Course Interested In</option>
                    <option value="1">VII</option>
                    <option value="2">VIII</option>
                    <option value="3">IX</option>
                    <option value="4">X</option>
                    <option value="5">XI</option>
                    <option value="6">XII</option>
                </Form.Select>
                <Form.Select required aria-label="Default select example" className='mb-3'>
                    <option>Select Prefered Center</option>
                    <option value="1">Nagpur</option>
                    <option value="2">Amravati</option>
                    <option value="3">Nashik</option>                                         
                </Form.Select>
                {/* <Form.Group className="mb-3">
                    <canvas id="captcha" width="200" height="80"></canvas>
                    <button type="button" class="btn btn-secondary" id="refresh-captcha">
                    <i class="fa fa-refresh"></i>
                    </button>
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check required type="checkbox" label="I agree to give my consent to receive updates through SMS/Email & WhatsApp*" />
                    <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
                </Form.Group>
                <div className="d-grid gap-2">
                    <Button className='theme_btn' type="submit" size='lg'>
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
};
    
export default ContactForm;


