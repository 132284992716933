import React from 'react';
import { Container, Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import Navigation from '../components/Navigation';

import aboutImg from '../assets/images/NagpurHO.png';
import CeoImg from '../assets/images/ceo-new.jpg';

import core1 from '../assets/images/icons/reading.png';
import core2 from '../assets/images/icons/education.png';
import core3 from '../assets/images/icons/light-bulb.png';
import core4 from '../assets/images/icons/transparency.png';
import core5 from '../assets/images/icons/profit.png';

import mentor1 from '../assets/images/mentors/Sarang-sir-Mentors.jpeg';
import mentor2 from '../assets/images/mentors/Swapnil-Upganlawar.jpg';
import mentor3 from '../assets/images/mentors/anil-kayande.jpg';
import mentor4 from '../assets/images/mentors/Sudhir-Bajpai.jpeg';
import mentor5 from '../assets/images/mentors/Kishore-Kumar.jpg';
import mentor6 from '../assets/images/mentors/Amit-Singh.jpg';
import mentor7 from '../assets/images/mentors/Akshay-Sharma.jpg';
import mentor8 from '../assets/images/mentors/Tinu-Agrawal.jpeg';

import academics1 from '../assets/images/academics/1.jpg';


import Breadcrumb from 'react-bootstrap/Breadcrumb';


const About = props => {
    return (
        <>
            <main>
                <div className='header_wrapper'>        
                    <Navigation/>                
                </div>
                <section className='breadcrumb_bg'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='breadcrumb_content'>
                                    <h1>About Us</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        {/* <Breadcrumb.Item href="">
                                            Library
                                        </Breadcrumb.Item> */}
                                        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                
                <section className='mt-50' data-aos="fade-right" data-aos-duration="3000" id='about-us'>
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <div className='mt-50'>
                                    <h2 className='title'>about us</h2>
                                    <p>ICAD is and will be totally devoted institute that is working towards protecting the interest of great Nation, India. ICAD is working and will work towards creating and nurturing of talent of the student, and towards creating well cultured personnel with clear concepts of life.</p>
                                    <h3 className='subtitle mb-0'>our vision</h3>
                                    <p>To be one of the Top 5 brands of India in Education Sector.</p>
                                    <h3 className='subtitle mb-0'>our Mission</h3>
                                    <p>To contribute to Nation Building by empowering young generation.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <Image src={aboutImg} alt="about image" fluid />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000" id='ceo-message'>
                    <Container>
                        <h2 className='title mb-30'>CEO message</h2>
                        <Row>
                            <Col sm={6}>
                                <Image src={CeoImg} alt="about image" fluid />
                            </Col>
                            <Col sm={6}>
                                <div><p>Dear parents and students,</p>
                                    <p>It is with immense pleasure and a deep sense of responsibility that I welcome you to ICAD School of Learning. At ICAD, we believe that education is not just about textbooks and examinations; it’s a journey of transformation that shapes the future of young minds. With a proven track record of nurturing young minds and helping them achieve their dreams, we are proud to offer a range of dynamic programs tailored to meet the unique needs of each student.</p>
                                    <p>Our commitment to excellence is reflected in every aspect of our institution. From our meticulously designed programs that cater to a diverse range of aspirations to our dedicated and qualified faculty who serve as mentors and guides, we strive to create an environment that nurtures innovation, curiosity, and holistic growth.</p>
                                </div>
                            </Col>                        
                        </Row>                    
                    </Container>
                    <Container>
                    <Row>
                            <Col sm={12}>
                                <p>Our tech-savvy solutions provide students with instant access to resources, online study materials, and educational tools, ensuring seamless learning in the digital era. Education is evolving, and so are we. With our tech-infused classrooms, unique evaluation system, and innovative learning resources, we are dedicated to preparing your children not just for exams, but for a lifetime of success.</p>
                                <p>I am thrilled to share the exceptional achievements of our students in the JEE Main (January 2024 ) Aditya Yadav scored an outstanding 99.951 Percentile, securing the OBC Topper position in Vidarbha. Sambhav Jha achieved a remarkable 99.857 Percentile, while Akshat Agrawal scored 99.751 Percentile. Tanush Badonia achieved a perfect 100% Percentile in Physics. Moreover, 28 students scored 99.0%ile and above, 54 achieved 98.0%ile and above, and a total of more than 650+ students qualified in JEE Mains in the January 24 attempt. 
                                    Our methodology is not just a promise; it’s a proven path to success. I invite you to join the league of achievers and set your child on the path to excellence with ICAD School of Learning. Together, let’s shape futures, ignite minds, and celebrate every milestone achieved by our students.
                                    Thank you for considering ICAD School of Learning. We look forward to being a part of your child’s remarkable journey.</p>
                                <p className='mb-0'>Warm regards,</p>
                                <h3 className='subtitle mb-0'>Sarang Upganlawar</h3>
                                <p className='mb-0'>CEO, ICAD School of Learning</p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='mt-50' data-aos="fade-up" data-aos-duration="3000" id='about-mentors'>
                    <Container>
                        <h2 className='title mb-30'>Mentors</h2>
                        <Row>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor1} alt='mentor-1' fluid />
                                        <Card.Title>Mr. sarang Upganlawar</Card.Title>                                    
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Mr. sarang Upganlawar</Card.Title>
                                        <p>M.PHARM, MBA</p>
                                        <p>FOUNDER & CEO</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor2} alt='mentor-2' fluid />
                                        <Card.Title>Mr. Swapnil Upganlawar</Card.Title>
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Mr. Swapnil Upganlawar</Card.Title>
                                        <p>MS (Computer Science, CMU, USA)</p>
                                        <p>MBA (Wharton Business School, USA)</p>
                                        <p>Senior Manager, AWS AI, Amazon Web Services, USA</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor3} alt='mentor-3' fluid />
                                        <Card.Title>Dr. anil kayande</Card.Title>
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Dr. anil kayande</Card.Title>
                                        <p>(M.Sc. (Mathematics), Ph.D.)</p>
                                        <p>Faculty Positions</p>
                                        <p>University of Alberta, Canada</p>
                                        <p>University of Rhode Island, RI, USA,</p>
                                        <p>University of Lfe, Nigeria</p>
                                        <p>Former Principal</p>
                                        <p>Ramdeobaba Engg. Col. Nagpur</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor4} alt='mentor-4' fluid />
                                        <Card.Title>Mr. Sudhir Kumar</Card.Title>
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Mr. Sudhir Kumar</Card.Title>
                                        <p>M.PHIL</p>
                                        <p>14 YEAR EXPERIENCE</p>
                                        <p>ACADAMIC HEAD - JEE & NEET</p>
                                        <p>HEAD OF DEPARTMENT (MATHS)</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor5} alt='mentor-5' fluid />
                                        <Card.Title>Mr. kishore kumar katta</Card.Title>                                    
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Mr. kishore kumar katta</Card.Title>
                                        <p>M.Sc. - Physics</p>
                                        <p>22 Years in JEE (Advanced) & Physics Olympiad Training</p>
                                        <p>Head of Department (Physics)</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor6} alt='mentor-6' fluid />
                                        <Card.Title>Mr. amit singh</Card.Title>
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Mr. amit singh</Card.Title>
                                        <p>M.SC</p>
                                        <p>21 YEAR EXPERIENCE</p>
                                        <p>Head of Department (Chemistry)</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor7} alt='mentor-7' fluid />
                                        <Card.Title>Mr. Akshay sharma</Card.Title>
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Mr. Akshay sharma</Card.Title>
                                        <p>M.Sc</p>
                                        <p>14 YEAR EXPERIENCE</p>
                                        <p>Academic Centre Head (NEET)</p>
                                        <p>Head of Department - (Biology)</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3} className='mb-15'>
                                <Card className='info-card'>
                                    <Card.Body>
                                        <Image src={mentor8} alt='mentor-8' fluid />
                                        <Card.Title>Dr. tinu agrawal</Card.Title>
                                    </Card.Body>
                                    <div className='hover-text'>
                                        <Card.Title>Dr. tinu agrawal</Card.Title>
                                        <p>ICAD Research and Development Head</p>
                                        <p>25 years experience</p>
                                        <p>BE (Civil and Applied Mechanics),</p>
                                        <p>MBA, PhD in Operations Management,</p>
                                        <p>70+ Research Papers in esteemed Publications and Journals.</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000" id='core-values'>
                    <Container>
                        <h2 className='title mb-30'>core values</h2>
                        <div className='core_cards'>
                            <Card>
                                <Card.Body>
                                    <Image src={core1} alt="core value image" fluid />
                                    <Card.Title>students first</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image src={core2} alt="core value image" fluid />
                                    <Card.Title>academic excellence</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image src={core3} alt="core value image" fluid />
                                    <Card.Title>process and product innovation</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image src={core4} alt="core value image" fluid />
                                    <Card.Title>transparency</Card.Title>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Image src={core5} alt="core value image" fluid />
                                    <Card.Title>employee welfare</Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </section>

                <section className='mt-50' data-aos="fade-up" data-aos-duration="3000" id='distingusing-academics'>
                    <Container>
                        <h2 className='title mb-30'>Distinguishing Academics</h2>
                        <h2 className='subtitle'>what makes ICAD vidarbha's best institute</h2>
                        <p>The only Institute to hold your hand throughout your entire journey untill your final success.</p>
                        <div className='vertical_tabs buttons'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                <Row>
                                    <Col sm={5}>                                        
                                        <Nav variant="pills">
                                            <Nav.Item>
                                                <Nav.Link eventKey="one">learning techniques</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="two">command capsule</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="three">online app</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="four">result booster</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="five">doubt clearing</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="six">discipline</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="seven">faculty</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="eight">academic system</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="nine">solving system</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="ten">personal attention</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="eleven">result creation system</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="twelve">mentorship</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={7}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="one">
                                                <div>
                                                    {/* <h2 className='title'>special learning techniques</h2> */}
                                                    <Image src={academics1} alt="details"></Image>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </Container>                    
                </section>
            </main>
        </>
    );
};

export default About;