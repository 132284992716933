import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Tabs, Tab, Button} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import { useLocation } from 'react-router-dom';
import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';


const GalleryDetails = props => {
    const gallaryImages = [
        'https://assets.codepen.io/12005/snowy.jpg',
        'https://assets.codepen.io/12005/snowy.jpg',
        'https://assets.codepen.io/12005/snowy.jpg',
        'https://assets.codepen.io/12005/snowy.jpg',
    ];
    
    const gallaryList = gallaryImages.map((item) => <img src={item} alt='photograph' />)


    const [key, setKey] = useState('seminar');
    const [activeKey, setActiveKey] = useState()
    console.log(activeKey);
    let location = useLocation();
    const hashValue = location.hash;
    const newKey = location.hash.slice(1);

    useEffect(() => {
        setActiveKey(newKey);
    }, []); 
    const handleClick = (key) => {
        if(location.hash) {
            window.history.replaceState("", document.title, window.location.pathname);
            setActiveKey(key);
            setKey(key);
       }
       else{
        setKey(key);
        setActiveKey(key);
       }
    }


    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Gallery Details</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/gallery">Gallery</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Gallery Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section  data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Tabs
                        defaultActiveKey='seminar'
                        id="controlled-tab-example"
                        className="mt-50 horizontal_tabs"
                        activeKey={activeKey}
                        onSelect={(key) => handleClick()}
                        justify
                        >
                            <Tab eventKey="seminar" title="Seminar">
                                <Tabs
                                    defaultActiveKey="first"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        <Tab eventKey="first" title="Chandrapur">
                                            <Button className='tabs_btn' variant="link">2023</Button>
                                            <SlideshowLightbox className='image_grid' >
                                                {gallaryList}
                                            </SlideshowLightbox>
                                        </Tab>
                                        <Tab eventKey="second" title="Nagpur East"></Tab>
                                        <Tab eventKey="third" title="Residential"></Tab>
                                </Tabs>
                            </Tab>
                            <Tab eventKey="pre-orientation" title="Pre Orientation">
                                orientation
                            </Tab>
                            <Tab eventKey="cpa" title="CPA">
                            </Tab>
                            <Tab eventKey="launch" title="Launch">
                            </Tab>
                            <Tab eventKey="leap-workshop" title="Leap Workshop">
                            </Tab>
                            <Tab eventKey="result" title="Result">
                            </Tab>
                            <Tab eventKey="feliciation" title="Feliciation">
                            </Tab>
                            <Tab eventKey="guidance-session" title="Guidance Session">
                            </Tab>
                            <Tab eventKey="science-visit" title="Science Visit">
                            </Tab>
                    </Tabs>
                </Container>
            </section>
        </main>
    );
};

export default GalleryDetails;