import React, {useState} from 'react';
import {Container, Row, Col, Image, Modal, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import alumniImages from '../data/alumniImages';
import AlumniForm from '../components/AlumniForm';

const IcadAlumni = props =>{    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>ICAD Alumni</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>ICAD Alumni</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <h1 className='mb-30 title'>Icadians</h1>
                                     
                    <Row>
                        <div className='d-block w-100'>
                            <Button className='theme_btn mb-3 float-end' onClick={handleShow}>
                                Click here to join ICAD Alumni
                            </Button>
                        </div>
                            
                        <Modal show={show} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                            <Modal.Title>Alumni Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><AlumniForm/></Modal.Body>                           
                        </Modal>   
                        {alumniImages.map(alumni => (
                            <Col md={2} sm={4} className='mb-4' data-aos="fade-up" data-aos-duration="3000">
                                <div className='student_img'>
                                    <Image src={alumni.image} alt='alumni images' fluid></Image>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default IcadAlumni;