import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import Navigation from '../components/Navigation';
import ContactForm from '../components/ContactForm';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CourseImg from '../assets/images/course.jpg';

import infoBrochure from '../assets/images/documents/info-brochure.pdf';

const Medical = props => {
    const [key, setKey] = useState('home');
    return (
        <>
            <main>
                <div className='header_wrapper'>        
                    <Navigation/>                
                </div>
                <section className='breadcrumb_bg'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='breadcrumb_content'>
                                    <h1>Medical</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Medical</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='mt-50'>
                    <Container className=''>
                        <Row className=''>
                            <Col sm={8}>
                                <div className='left_panel'>
                                    <Image src={CourseImg} alt='course-image' className='mb-15' />
                                    <h1 className='title'>medical</h1>
                                    <p><b>Dear Medical Aspirants,</b><br/>Successful NEET aspirants are quite determined and focused individuals. Institution also plays its pivotal role in providing best training, guidance, disciplined culture and direction. We are not only providing result oriented culture but also making them perform better than that they are at entry level.</p>

                                    <p>In 2015, ICAD commenced its prestigious Medical Training Entrance Program with a vision to set landmark in this field as it has established in Engineering Entrance Examinations. As expected, ICAD medical program is immensely successful and since very first result in 2017 ICADians easily sailed through Medical entrance exams and got admissions in AIIMS, KEM-Mumbai, Grand Medical college-Mumbai, AFMC Pune, GMC Nagpur, IGMC-Nagpur and other Government and top Private Medical Colleges. And every year the legacy continues with Top Medical Results from respective batches.</p>

                                    <p>Medical Entrance Examinations is witnessing changes for the past few years. ICAD has its strength in constant innovation and adaptability. In last five Years, ICAD developed its own 'Lecture Plans' for NEET & AIIMS program. ICAD came up with many process innovations like "SOLVE: 6 STEP SOLVING SYSTEM', 'MENTORING', 'RANK BOOSTER PROGRAM', 'STUDY TECHNIQUES', AND 'TEST ANALYSIS' along with ICAD Digital App.</p>

                                    <p>Those students who are willing to become medical professionals through NEET-UG and scientists through KVPY are only encouraged joining <b>ICAD MEDICAL</b>. We are confident that our courses will definitely help to prepare and shape student's innate intelligence and take them to the highest possible levels through rigorous training.</p>

                                    <p>We welcome you to the journey of your dream career!</p>
                                </div>
                                <div>
                                <Tabs
                                defaultActiveKey="first"
                                id="uncontrolled-tab-example"
                                className="mb-3 theme_tabs"
                                >
                                    <Tab eventKey="first" title="Features">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>Make a difference to your NEET preparation with ICAD Medical</h2>
                                            <ul className='theme_list'>
                                                <li>Teaching & doubts solving by most renowned, qualified & experienced faculty of ICAD.</li>
                                                <li>Result producing academic system- Excellent track record of our outstanding results in medical entrance exams & in KVPY from Vidharbha.</li>
                                                <li>Daily six hours classes: All three subjects with daily practice of multiple choice questions (DPP). DDP will help the students to get familiar with easy to difficulty level questions in their day to day practice for the entrance.</li>
                                                <li>Revision Test- Every alternate Sunday or twice in a month compulsory revision tests (RT) to check your current studies. The RT will be based on the topics taught by faculty at the institute and the pattern of the paper will be like real NEET.</li>
                                                <li>Study Material: Our Comprehensive study material is curated by subject matter experts that empower with an in-depth understanding of all crucial topics of physics, chemistry & biology to stay ahead in the competition.</li>
                                                <li>Integrated Teaching: Our integrated teaching approach not only ensures that you are listed as a top achiever in NEET & KVPY but also to makes you shine in your board exams.</li>
                                                <li>ICAD Digital App for students for online study support.</li>
                                                <li>Parent Connect app: This is an important tool for the parents and the students-
                                                <ul className='theme_list' style={{marginTop:'15px'}}>
                                                    <li>To receive updates of results, attendance, appointment & other activities from us, parents have to download ICAD's Parent Connect App from google playstore. The app will work only on parent / communication number registered with institute.</li>
                                                    <li>The app is a very useful tool to monitor student's progress; it gives real time attendance of student in class/session / test etc. It also gives performance details of every test and graphical analysis. Parents can request appointment with team or any other matter through App.</li>
                                                    <li>Important notification is also published through App.</li>
                                                </ul>
                                                </li>
                                            </ul>

                                        </div>
                                    </Tab>
                                    <Tab eventKey="second" title="2 Years Program">
                                        <div className='mb-30'>
                                            <h2 className='subtitle underline mb-15'>2 YEARS CLASSROOM PROGRAM (For XI Moving): Nagpur:</h2>
                                            <div>
                                                <h2 className='subtitle mb-0'>EXCEL - MEDICAL (Nagpur program)</h2>
                                                <p>EXCEL - MEDICAL is a 2 years program after X. Excel Medical Program will be covering PCB of XI-XII, Board Exam and Medical Entrance Exam respectively.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure} target="_blank">Information Brochure</a></li>
                                                        <li><a href='#enquiry-form'>Online Admission Form</a></li>
                                                        <li><a href=''>CPA Sample Paper</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div>
                                            <h2 className='subtitle underline mb-15'>2 YEARS CLASSROOM PROGRAM (For XI Moving): Residential:</h2>
                                            <div>
                                                <h2 className='subtitle mb-0'>EXCEL - MEDICAL (Residential Program)</h2>
                                                <p>EXCEL - MEDICAL is a 2 years program after X. Excel Medical Program will be covering PCB of XI-XII, Board Exam and Medical Entrance Exam respectively.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure} target="_blank">Information Brochure</a></li>
                                                        <li><a href='' target="_blank">Recidential Brochure</a></li>
                                                        <li><a href='#enquiry-form'>Online Admission Form</a></li>
                                                        <li><a href='/contact'>Enquiry</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="third" title="Rank Booster Program">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>Rank Booster PROGRAM (Test Series):</h2>
                                            <div>
                                                <h2 className='subtitle mb-0'>FINAL CALL - MEDICAL</h2>
                                                <p>Rank Booster Test series for NEET is designed according to latest NTA pattern to help student to get familiar with paper pattern and the type of questions they will face in the actual exam.</p>
                                                <p>Rank Booster is a great revision tool that provides you with an opportunity to assess your preparation and strengthen the weak areas to outperform. This is a test series based on full course, conducted exactly as per the Medical pattern to prepare student for the final day.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure} target='_blank'>Information Brochure</a></li>
                                                        <li><a href='/contact'>Enquiry</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="fourth" title="Repeaters">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>Repeaters:</h2>
                                            <div>
                                                <p>Repeater batch is a one year training program for selected candidates who are keenly interested to crack NEET and want the specialized guidance to keep themselves prepared in the competition for higher ranks.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure} target='_blank'>Information Brochure</a></li>
                                                        <li><a href='#enquiry-form'>Online Admission Form</a></li>
                                                        <li><a href='/contact'>Enquiry</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                </Tabs>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="d-grid gap-2 mb-30">
                                    <Button className='theme_btn' size="lg" href='#enquiry-form'>Enquire now</Button>
                                    <Button className='theme_btn' size="lg" href='/results'>Results</Button>
                                </div>
                                <Card className='mb-30 theme_card'>
                                    <Card.Body>
                                        <h2 className='subtitle underline'>Other Courses</h2>
                                        <ul className='custom_list'>
                                            <li><a href='/engineering'>Engineering</a></li>
                                            <li><a href='/foundation'>Foundation</a></li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                                <Card id='enquiry-form' className='theme_card'>
                                    <Card.Body>
                                        <h2 className='subtitle underline mb-15'>Enquiry Form For JEE</h2>
                                        <ContactForm/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
};

export default Medical;
