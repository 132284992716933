import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import Navigation from '../components/Navigation';
import ContactForm from '../components/ContactForm';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CourseImg from '../assets/images/course.jpg';

import infoBrochure from '../assets/images/documents/info-brochure.pdf';


const Engineering = props => {
    const [key, setKey] = useState('home');
    return (
        <>
            <main>
                <div className='header_wrapper'>        
                    <Navigation/>                
                </div>
                <section className='breadcrumb_bg'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='breadcrumb_content'>
                                    <h1>Engineering</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Engineering</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className='mt-50'>
                    <Container className=''>
                        <Row className=''>
                            <Col sm={8}>
                                <div className='left_panel'>
                                    <Image src={CourseImg} alt='course-image' className='mb-15' />
                                    <h1 className='title'>Engineering</h1>
                                    <p>ICAD offers top class training courses which are designed & conceptualized based on simple structure, efficient testing, easy grasping & Student's capability. ICAD's offering is much more than just a classroom training. It's truly a learning experience.</p>
                                </div>
                                <div>
                                <Tabs
                                defaultActiveKey="first"
                                id="uncontrolled-tab-example"
                                className="mb-3 theme_tabs"
                                >
                                    <Tab eventKey="first" title="2 Years Program">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>2 YEARS PROGRAM (For XI Moving) Classroom/Residential:</h2>
                                            <div>
                                                <h2 className='subtitle mb-0'>EXCEL - JEE</h2>
                                                <p>EXCEL - JEE is a 2 years program after X. It covers MHT-CET, JEE Main & Advanced, BITSAT, XI-XII CBSE / State Board</p>
                                            </div><hr/>
                                            <div>
                                                <h2 className='subtitle mb-0'>MASTER - JEE</h2>
                                                <p>MASTER - JEE is a 2 years program after X. It covers MHT-CET, JEE Main & XI-XII CBSE / State Board</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure}>Information Brochure</a></li>
                                                        <li><a href=''>Residential Brochure</a></li>
                                                        <li><a href=''>CPA Registration</a></li>
                                                        <li><a href=''>ICAD's Entrance (CPA) Application Form</a></li>
                                                        <li><a href=''>Sample Paper</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>

                                        </div>
                                    </Tab>
                                    <Tab eventKey="second" title="1 Year Program">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>1 YEAR PROGRAM (For XII Moving):</h2>
                                            <div>
                                                <h2 className='subtitle mb-0'>LATERAL - JEE</h2>
                                                <p>LATERAL ENTRY is a 1 year Training Program after XI for selected candidates who started their JEE prepatation, but want the specialized guidance to keep themselves prepared in competition for higher ranks.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure}>Information Brochure</a></li>
                                                        <li><a href='/contact'>Enquiry</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="third" title="Rank Booster Program">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>Rank Booster PROGRAM (Test Series):</h2>
                                            <div>
                                                <h2 className='subtitle mb-0'>FINAL CALL - JEE</h2>
                                                <p>This is a test series based on full course, conducted exactly as per the JEE pattern to prepare student for the final day.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure}>Information Brochure</a></li>
                                                        <li><a href='/contact'>Enquiry</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="fourth" title="Repeaters">
                                        <div>
                                            <h2 className='subtitle underline mb-15'>Repeaters:</h2>
                                            <div>
                                                <p>This is a batch for selected candidates, who are serious about improving score & Rank for JEE.</p>
                                            </div>
                                            <Card className=''>
                                                <Card.Body>
                                                    <ul className='download_list'>
                                                        <li><a href={infoBrochure}>Information Brochure</a></li>
                                                        <li><a href='/contact'>Enquiry</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>
                                </Tabs>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="d-grid gap-2 mb-30">
                                    <Button className='theme_btn' size="lg" href='#enquiry-form'>Enquire now</Button>
                                    <Button className='theme_btn' size="lg" href='/results'>Results</Button>
                                </div>
                                <Card className='mb-30 theme_card'>
                                    <Card.Body>
                                        <h2 className='subtitle underline'>Other Courses</h2>
                                        <ul className='custom_list'>
                                            <li><a href='/medical'>Medical</a></li>
                                            <li><a href='/foundaion'>Foundation</a></li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                                <Card id='enquiry-form' className='theme_card'>
                                    <Card.Body>
                                        <h2 className='subtitle underline mb-15'>Enquiry Form For JEE</h2>
                                        <ContactForm/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
};

export default Engineering;
