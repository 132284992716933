import React from 'react';
import {Container, Row, Col, Card, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navigation from '../components/Navigation';

import engIcon from '../assets/images/icons/engineering.png';
import resultIcon from '../assets/images/icons/trophy.png';
import medicalIcon from '../assets/images/icons/doctor.png';
import foundationIcon from '../assets/images/icons/left-up.png';


const Results = props => {
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Results</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    {/* <Breadcrumb.Item href="">
                                        Library
                                    </Breadcrumb.Item> */}
                                    <Breadcrumb.Item active>Results</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h2 className='title mb-30'>results</h2>
                    <Row>
                        <Col md={3} sm={6}>
                            <Card className='theme_card course_card'>
                                <Card.Body>
                                <div className='icon_div mb-15'><Image src={resultIcon} fluid></Image></div>
                                <h4>Overall Results</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <a href=''>View Details-></a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={6} className='mb-15'>
                            <Card className='theme_card course_card'>
                                <Card.Body>
                                <div className='icon_div mb-15'><Image src={engIcon} fluid></Image></div>
                                <h4>Engineering Results</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <a href=''>View Details-></a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={6} className='mb-15'>
                            <Card className='theme_card course_card'>
                                <Card.Body>
                                <div className='icon_div mb-15'><Image src={medicalIcon} fluid></Image></div>
                                <h4>Medical Results</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <a href=''>View Details-></a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={6} className='mb-15'>
                            <Card className='theme_card course_card'>
                                <Card.Body>
                                <div className='icon_div mb-15'><Image src={foundationIcon} fluid></Image></div>
                                <h4>Foundation Results</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <a href=''>View Details-></a>
                                </Card.Body>
                            </Card>
                        </Col>                        
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default Results;


