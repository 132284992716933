import React from 'react';
import { Container, Row, Col, Tabs, Tab} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';

const IcadStars = props => {
    return(
       <main>
          <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>   
                            <div className='breadcrumb_content'>
                                <h1>ICAD Stars</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>ICAD Stars</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section  data-aos="fade-up" data-aos-duration="3000" className='mt-50'>
                <Container>   
                    <h1 className='title mb-30'>ICAD Stars</h1>                 
                    <Tabs
                        id="justify-tab-example"
                        defaultActiveKey="1"
                        className="horizontal_tabs star_tabs"
                        justify                    
                        >                            
                            <Tab eventKey='1' title='Olympiads'>
                                dsgf
                            </Tab>
                            <Tab eventKey='2' title='INMO'>
                                fsfs
                            </Tab>
                            <Tab eventKey='3' title='INChO'></Tab>
                            <Tab eventKey='4' title='INPhO'></Tab>
                            <Tab eventKey='5' title='RMO'></Tab>
                            <Tab eventKey='6' title='KVPY'></Tab>
                            <Tab eventKey='7' title='AMTI'></Tab>
                            <Tab eventKey='8' title='JEE‑Adv'></Tab>
                            <Tab eventKey='9' title='Board'></Tab>
                            <Tab eventKey='10' title='JEE‑Main'></Tab>
                            <Tab eventKey='11' title='BITSAT'></Tab>

                    </Tabs>
                </Container>
            </section>
       </main>
    );
};

 export default IcadStars;