import React from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import facebookIcon from '../assets/images/icons/facebook.svg';
import twitIcon from '../assets/images/icons/twitter.svg';
import blogIcon from '../assets/images/icons/blog.svg';
import ytubeIcon from '../assets/images/icons/youtube.svg';
import instaIcon from '../assets/images/icons/instagram.svg';

const TopBar = props => {
  return (
    <div className="top_nav">
      <Container fluid="lg">
        <div className='d-flex'>
          <div className='top_left'>
              <ul>
                  <li><b>Call Us:</b> +91-9355870236, +91-9355870232</li>
                  <li><b>Mail Us:</b> contact@icadiit.com</li>
              </ul>
          </div>
          <div className='top_right'>
            <ul>
              <li><a href=''><img src={facebookIcon} className='svg_img'/></a></li>
              <li><a href=''><img src={twitIcon} className='svg_img'/></a></li>
              <li><a href=''><img src={ytubeIcon} className='svg_img'/></a></li>
              <li><a href=''><img src={blogIcon} className='svg_img'/></a></li>
              <li><a href=''><img src={instaIcon} className='svg_img'/></a></li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopBar;