import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';

import ContactForm from '../components/ContactForm';


import Navigation from '../components/Navigation';

import email from '../assets/images/icons/email.png';
import location from '../assets/images/icons/location.png';
import phone from '../assets/images/icons/phone-call.png';
import centerImg from '../assets/images/ICAD_west.jpg';

const ContactUs = props => {
    return(
        <>
            <main>
                <div className='header_wrapper'>        
                    <Navigation/>                
                </div>
                <section className='breadcrumb_bg'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='breadcrumb_content'>
                                    <h1>Contact Us</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                    <Container>
                        <h2 className='title mb-30'>Contact Information</h2>
                        <div className='vertical_tabs'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={5}>                                        
                                        <Nav variant="pills" className="flex-column">
                                            <h2 className='subtitle mb-0'>Nagpur</h2>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">ICAD Corporate Office & Study Center</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">ICAD NEET Study Center - Nagpur (Dharampeth)</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">ICAD Corporate Center - Nagpur (East)</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="forth">ICAD Corporate Center - Nagpur (Sadar)</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fifth">ICAD Corporate Center - Nagpur (Chhatrapati Nagar)</Nav.Link>
                                            </Nav.Item>
                                            <a href="/residential" className='nav-link mb-0'>Residential Centre (Campus)</a><br/>
                                            <h2 className='subtitle mb-0'>amravati</h2>
                                            <Nav.Item>
                                                <Nav.Link eventKey="sixth">ICAD Corporate Center - Amravati</Nav.Link>
                                            </Nav.Item><br/>
                                            <h2 className='subtitle mb-0'>chandrapur</h2>
                                            <Nav.Item>
                                                <Nav.Link eventKey="seventh">ICAD Corporate Center - Chandrapur</Nav.Link>
                                            </Nav.Item><br/>
                                            <h2 className='subtitle mb-0'>yavatmal</h2>
                                            <Nav.Item>
                                                <Nav.Link eventKey="eight">ICAD Corporate Center - Yavatmal</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={7}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Head Office & Study Centre:</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>ICAD Heights (West Nagpur)
                                                                        21, Tilak Nagar, Opp. Basket Ball Ground
                                                                        Nagpur - 440010.
                                                                        Maharashtra State, INDIA</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Head Office & Study Centre:</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>1st & 2nd Floor, Imperial Court
                                                                        Near Children Traffic Park,
                                                                        Khare Town, Dharampeth,
                                                                        Nagpur - 440010
                                                                        Maharashtra State, India</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                            <div>
                                                <h2 className='subtitle mb-15'>ICAD Corporate Center - Nagpur (East):</h2>
                                                <Row>
                                                    <Col sm={8}>
                                                        <ul className='contact_info_list'>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={location} alt='location icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Location Address:</h5>
                                                                    <p className='mb-0'>H.No:780, Plot No.:389-A, Hanuman Nagar Layout,
                                                                    Near PRragati Bhawan, Manewada Road,
                                                                    Nagpur - 440024.
                                                                    Maharashtra State, INDIA</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={phone} alt='phone icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Contact NO.:</h5>
                                                                    <p className='mb-0'>+91-9355870236</p>
                                                                    <p className='mb-0'>+91-9355870232</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='icon_div'>
                                                                    <Image src={email} alt='email icon'></Image>
                                                                </div>
                                                                <div className='details_content'>
                                                                    <h5 className='mb-0'>Email Address:</h5>
                                                                    <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={centerImg} className='center image' rounded fluid></Image>
                                                    </Col>
                                                </Row>
                                            </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="forth">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Center - Nagpur (Sadar):</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>"Sunder Pride", 2nd Floor,
                                                                        Plot No. 25, Chitnavis Layout,
                                                                        Byramji Town,
                                                                        Nagpur
                                                                        Maharashtra State, INDIA</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fifth">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Center - Nagpur (Chhatrapati Nagar):</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>"2nd Floor,
                                                                        Chhatrapati Nagar Metro Station,
                                                                        Chhatrapati Nagar,
                                                                        Nagpur - 440015
                                                                        Maharashtra State, INDIA</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="sixth">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Center - Amravati:</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>Bhende Business Plaza,
                                                                        Khaparde Garden,
                                                                        Opposite Railways Station Gate,
                                                                        Amravati - 444601.
                                                                        Maharashtra State, INDIA</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="seventh">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Center - Chandrapur:</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>2nd Floor, Zanzari Complex,
                                                                        Beside Rasraj Hotel, Z.P Road,
                                                                        Chandrapur - 442401
                                                                        Maharashtra State, India</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="eight">
                                                <div>
                                                    <h2 className='subtitle mb-15'>ICAD Corporate Center - Yavatmal:</h2>
                                                    <Row>
                                                        <Col sm={8}>
                                                            <ul className='contact_info_list'>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={location} alt='location icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Location Address:</h5>
                                                                        <p className='mb-0'>Ratan Imperial, 2nd Floor,
                                                                        Mahadev Mandir Road,
                                                                        Yavatmal - 445001
                                                                        Maharashtra State, India</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={phone} alt='phone icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Contact NO.:</h5>
                                                                        <p className='mb-0'>+91-9355870236</p>
                                                                        <p className='mb-0'>+91-9355870232</p>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className='icon_div'>
                                                                        <Image src={email} alt='email icon'></Image>
                                                                    </div>
                                                                    <div className='details_content'>
                                                                        <h5 className='mb-0'>Email Address:</h5>
                                                                        <p className='mb-0'><a href='email:contact@icadiit.com'>contact@icadiit.com</a></p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            </Col>
                                                        <Col sm={4}>
                                                            <Image src={centerImg} className='center image' rounded fluid></Image>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </Container>
                </section>
                <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                    <Container>
                        <h2 className='title mb-30'>get in touch</h2>
                        <Row>
                            <Col sm={6}>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <ContactForm/>
                                    </Card.Body>
                                </Card>                                
                            </Col>
                            <Col sm={6}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3721.1881084215706!2d79.058368!3d21.144911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c05ddc7a71eb%3A0xa27a7df06f1d92c8!2sICAD!5e0!3m2!1sen!2sin!4v1430290770386" width="100%" height="100%" frameborder="0"></iframe>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>      
    );
};

export default ContactUs;