import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import Navigation from '../components/Navigation';

import FormModal from '../components/FormModal';


const Admission = props => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <main>
                <div className='header_wrapper'>
                    <Navigation />
                </div>
                <section className='breadcrumb_bg'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className='breadcrumb_content'>
                                    <h1>Admission</h1>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Admission</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='mt-50'>
                    <Container>
                        <Row className='mb-30'>
                            <Col sm={12}>
                                <div className='mb-30'>
                                    <h2 className='title'>admissions and scholarships</h2>
                                </div>
                                <Card className='theme_card'>
                                    <Card.Body>
                                        <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>CPA (Competitive Potential Assessment) Test:</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>CPA is a unique test which verifies your analytical skills acquired at high school level. CPA is designed in such a way that the conceptual understanding of the subject knowledge is finely tested. The popularity of CPA is in its simple form, which tests the complex concepts in the most lucid and straight – forward way. A high performance in CPA truly reflects the conceptual clarity of the student.</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>admissions and scholarships:</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Aspiring students are admitted at ICAD based on their performance in ICAD’s admission & scholarship (CPA) test. Prospective ICADians are eligible for 10 - 100% waiver in their tuition fees based on their performance in <b>ICAD’s Scholarship & Admission Competitive Potential Assessment (CPA) Test.</b></p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>cpa sample paper:</Accordion.Header>
                                                <Accordion.Body>
                                                    <p>Aspiring students can download CPA sample paper at <a href='/downloads'>www.icadiit.com/downloads.php</a></p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={12} className='mb-15 d-flex'>
                                <h2 className='subtitle'>FOR ONLINE ADMISSION FORM - ENGINEERING (JEE) / MEDICAL (NEET)</h2>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button className='theme_btn' onClick={() => setModalShow(true)}>Click Here</Button>
                            </Col>
                            <Col sm={12} className='d-flex'>
                                <h2 className='subtitle'>FOR ONLINE ADMISSION FORM - FOUNDATION (EXCEL / MASTER)</h2>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button className='theme_btn' onClick={() => setModalShow(true)}>Click Here</Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <FormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default Admission;