import React, {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navigation from '../components/Navigation';

import blogData from '../data/blog';

import BlogItem from '../components/BlogItem';

const Blog = props => {
    const [blogList, setBlogList] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();

    useEffect(() => {
        setBlogList(blogData);
    }, []);   
    
    function getFilteredList() {
        if (!selectedCategory) {
          return blogList;
        }
        return blogList.filter((item) => item.category === selectedCategory);
      }

      var filteredList = useMemo(getFilteredList, [selectedCategory, blogList]);

      function handleCategoryChange(event) {
        setSelectedCategory(event.target.value);
     }
    return(
        <main>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Blog</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Blog</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <h1 className='title mb-30'>Blog List</h1>
                    <div className="filter_container mb-4">
                        <div><h5>Filter by Category:</h5></div>
                        <div>
                            <select
                                className="custom_select form-select"
                                name="category-list"
                                id="category-list"
                                onChange={handleCategoryChange}
                            >
                                <option value="">All</option>
                                {blogData.map(blog => {
                                    return(
                                    <option value={blog.category}>{blog.category}</option>                                                                           
                                )})};
                            </select>
                        </div>
                    </div>
                    <Row>
                        <Col sm={12}>
                        {filteredList.map((element, index) => (
                            <BlogItem {...element} key={index} />
                        ))}
                        </Col>                  
                    </Row>
                </Container>
            </section>
        </main>
    );
};

export default Blog;