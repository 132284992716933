import React from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import bannerLeft from '../assets/images/banner-left.mp4';
import bannerRight from '../assets/images/banner-right.jpeg';
import center1 from '../assets/images/center-banner1.jpeg';
import center2 from '../assets/images/center-banner2.jpeg';



const Banner = props => {
  return (
    <>
      <section className='banner_sec'>
        <Container className='mx-auto' fluid>
          <div className='justify-content-center'>
            <h2><i>shaping futures, igniting brilliance</i></h2>
          </div>
          <div className='mx-auto flex_component relative'>
            <div className='banner_slider'>
            <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={center1}
                  alt="first slide"
                />
                {/* <Carousel.Caption>
                  <h5>Second slide label</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={center2}
                  alt="second slide"
                />
                {/* <Carousel.Caption>
                  <h5>Third slide label</h5>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p>
                </Carousel.Caption> */}
              </Carousel.Item>
            </Carousel>
            </div>
            <div className='popout_images'>
            <video className="swing left" autoPlay loop muted>
            <source
              src={bannerLeft}
              type="video/mp4"
              allowFullScreen
            />
            </video>
              {/* <iframe src={bannerLeft} width="100%" height="100%" frameborder="0"></iframe> */}
              <Image src={bannerRight} alt='right-img' className='swing right' fluid />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Banner;