import studentImage from  '../assets/images/students/anujapte.png';

export default [
    {
        id: "1",
        name:"Anuj Apte",
        photo:studentImage,
        profile:"Physics Prodigy of India",
        info:"<p>Anuj Apte, a physics prodigy from Nagpur, India, demonstrated academic excellence from his school days at Somalwar, Nikalas. He became the first student from Vidarbha to achieve a remarkable feat of winning three medals in various International Olympiads for India.<br/><br/>Anuj's journey began with joining ICAD for JEE training in VIII. Notably, he qualified for the Indian National Physics Olympiad (INPhO) in XI, a rare accomplishment as only two XI standard candidates from India were selected. Anuj finished studying Physics up to the post-graduation level during his XII year.<br/>In XII, Anuj was selected for the Asian Physics Olympiad and International Physics Olympiad, earning recognition as the top student in the Indian Camp. Despite the pressure of JEE (Advanced) preparation, Anuj sacrificed the last 20 days to attend the Indian camp, where he represented India at the Asian Physics Olympiad in China and won a Gold Medal.<br/>Although Anuj could have secured a top rank in JEE (Advanced) with focused preparation, he chose to prioritize the international physics competitions. His decision to attend the Asian Physics Olympiad paid off with a Gold Medal but impacted his JEE (Advanced) ranking, landing him at AIR 79.<br/>In the International Physics Olympiad, Anuj narrowly missed the Gold Medal but still achieved a remarkable Silver Medal. Despite having the opportunity to pursue B. Tech in Computer Science at IIT-Powai, Anuj decided to follow his passion for physics and opted for admission to the Indian Institute of Sciences (IISC) Bangalore.<br/>Anuj's story is one of academic excellence, dedication to physics, and the pursuit of passion over conventional success, showcasing his commitment to his chosen field.</p>"
    },
    {
        id: "2",
        name:"Rupanshu Ganvir",
        photo:studentImage,
        profile:"Amazing Talent in Physics and Mathematics",
        info:"sdf"
    },
    {
        id: "3",
        name:"Pranjal Warade",
        photo:studentImage,
        profile:"Maths Prodigy in Girls by TOI",
        info:"sdf"
    },
    {
        id: "4",
        name:"Ishan Khot",
        photo:studentImage,
        profile:"Mathematics Genius",
        info:"sdf"
    }
];