import React from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const AlumniForm = props => {
    return(
        <>
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
                    <Form.Label column sm="4">
                        <b>Name</b>
                    </Form.Label>
                    <Col sm="4">
                        <Form.Control type="text" placeholder="Enter First Name" />
                    </Col>
                    <Col sm="4">
                        <Form.Control type="text" placeholder="Enter Last Name" />
                    </Col>
                </Form.Group> 
                <Form.Group as={Row} className="mb-3" controlId="formMobileNo">
                    <Form.Label column sm="4">
                        <b>Mobile No.</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" placeholder="Enter Mobile No." />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formEmail">
                    <Form.Label column sm="4">
                        <b>Email ID</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control type="email" placeholder="Enter Email ID" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formAddress">
                    <Form.Label column sm="4">
                        <b>Address</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control as="textarea" rows={2} placeholder="Enter Address" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formBatch">
                    <Form.Label column sm="4">
                        <b>Your Batch @ICAD</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Select aria-label="Default select example">
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </Col>
                </Form.Group> 
                <Form.Group as={Row} className="mb-3" controlId="formAcademicDetails">
                    <Form.Label column sm="4">
                        <b>Current Academic Details</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" placeholder="Enter Current Academic Details" />
                    </Col>
                </Form.Group>  
                <Form.Group as={Row} className="mb-3" controlId="formWorkingDetails">
                    <Form.Label column sm="4">
                        <b>Current Working Details</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control as="textarea" rows={2} placeholder="Enter Current Working Details" />
                    </Col>
                </Form.Group> 
                <Form.Group as={Row} className="mb-3" controlId="formPhoto">
                    <Form.Label column sm="4">
                        <b>Your Recent Photo</b>
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control type="file" size="md" />
                    </Col>
                </Form.Group>
                <hr/> 
                <div className='d-flex justify-content-end'>
                    <Button className='theme_btn'>Submit</Button> 
                </div>           
            </Form>
        </>
    );
};

export default AlumniForm;